@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700;900&family=Yellowtail&display=swap');

@value borderHeight: 0.5em;

.app {
    background: black;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    font-family: 'Red Hat Display', sans-serif;
    position: relative;
    overflow: hidden;
}

.auth {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderContainer {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
}

.loader {
    flex: 1;
    height: 15vh;
}

.authKeyContainer {
    flex: 1;
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 4vh;
    font-weight: bold;
    flex-direction: column;
}

.authMessage {
    flex: 1;
    text-transform: capitalize;
}

.authCodeContainer {
    flex: 1;
    display: flex;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 2vh;
    /* font-weight: bold; */
    flex-direction: column;
}

.authCodeMessage {
    flex: 1;
}

.authKey {
    flex: 1;
    width: 25%;
}

.versionFooter {
    bottom: 5px;
    position: fixed;
    color: white;
    text-align: center;
    font-size: large;
}


.slideShowImage {
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-size: contain;
}

.promptImage {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
}

.promptBorder {
    height: 90%;
    border: 50px solid transparent;
    border-image-source: url('/border.jpg');
    border-image-slice: 46 49;
    border-image-repeat: round;
}

.promptBorder:after {
    clear: both;
    content: "";
    display: table;
}

.messageContainer {
    position: absolute;
    color: white;
    display: flex;
    min-height: 10vh;
    bottom: 5vh;
    background: rgba(0, 0, 0, 0.4);
    width: 50vw;
    right: 0;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.promptMessageContainer {
    position: absolute;
    z-index: 2000;
    display: flex;
    width: 100%;
    bottom: 5vh;
    justify-content: center;
    align-items: center;
    background: transparent;
    flex-wrap: wrap;
}

.promptMessage {
    z-index: 2000;
    background: #eafde9;
    font-size: 4vh !important;
    color: rgba(0, 0, 0, 0.75);
    padding: 0 10vh !important;
    background: url('assets/messageBackrgound.svg');
    background-repeat: repeat-x;
    background-size: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.notificationContainer {
    position: absolute;
    top: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    margin-right: 6vh;
    z-index: 1000;
}

.notificationIconContainer {
    height: 10vh;
    width: 10vh;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.notificationImage {
    height: 58%;
}

.notificationMessageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
    background: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 0.7);
    padding: 1vh 2vh 1vh 3vh;
    margin-left: -2vh;
    border-radius: 0.5vh;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.message {
    font-size: 3vh;
    padding: 0 2vh 0 2vh;
    text-align: center;
}

.promptSlide {
    height: 100vh;
    width: 100vw;
}

.eventContainer {
    position: absolute;
    bottom: 5vh;
    height: 10vh;
    left: 3vw;
    width: 40vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.8;
}

.eventDetails {
    flex-direction: column;
    padding-left: 1vw;
    font-size: 2.4vh;
    color: white;
}

.eventDetails p {
    margin: 0;
}

.eventIcon {
    font-size: 6vh;
    color: white;
    padding: 1vh
}

.eventName {
    font-size: 3.4vh;
    font-weight: bold;
}

.watch {
    position: absolute;
    left: 4vw;
    bottom: 11.5vh;
    opacity: 0.8;
}

.time {
    font-size: 8.5vh;
    color: white;
    font-weight: bold;
}

.date {
    font-size: 3.8vh;
    color: white;
    font-weight: bold;
}

.cacheImage {
    position: absolute;
    top: -1px;
    left: -1px;
}

.promptToast {
    position: fixed;
    width: 30vw;
    right: 0;
    text-align: center;
    font-size: 3vh;
    top: 1vh;
    background: rgba(255, 255, 255, 1);
    color: black;
    text-transform: capitalize;
    font-family: 'Red Hat Display', sans-serif;
}

.noSlideShow {
    color: white;
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
    opacity: 0.8;
}

.offlineContainer {
    position: absolute;
    top: 0;
    background: #debb42;
    height: 4vh;
    left: 0;
    width: 100vw;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vh;
    font-weight: 500;
}

.onlineContainer {
    background: #42de57;
}

@keyframes wobble {
    0% {
        -webkit-transform: none;
        transform: none;
        opacity: 0;
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        opacity: 0.6;
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    100% {
        -webkit-transform: none;
        transform: none;
        opacity: 1;
    }
}

.notificationWobble {
    animation: wobble 2s;
}

.messageSlideContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.messageSlideContent {
    color: white;
    font-size: 8vh;
    font-weight: bold;
    margin: 10% 15%;
    overflow: auto;
    height: 80vh;
    width: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.authCodeContainer {
    flex: 1 1;
    display: flex;
    color: white;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    font-size: 4vh;
    font-weight: bold;
    flex-direction: column;
    height: 80%;
}

.numberPad {
    display: flex;
    flex-wrap: wrap;
}

.key {
    font-size: 3vh;
    font-weight: normal;
    padding: 2vh;
    border: 0.1vh solid rgba(255, 255, 255, 0.1);
}

.keyContainer {
    flex-basis: 33%;
}

.activeKey {
    border-color: rgba(255, 255, 255, 1);
}

.codeField {
    min-width: 50%;
    background: rgba(0, 0, 0, 0);
    border: none;
    border-bottom: 0.1vh solid white;
    font-size: 4vh;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    outline: none;
}

/* Chrome, Safari, Edge, Opera */
.codeField::-webkit-outer-spin-button,
.codeField::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.codeField[type=number] {
    -moz-appearance: textfield;
}

.codeFieldPlaceholder {
    font-weight: normal;
    color: rgba(255, 255, 255, 0.75);
}

.emojiPageContainer {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.emojiContainer {
    display: flex;
    height: 40%;
    width: 40%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.emoji {
    content: attr(data-emoji);
    height: 3em;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 25%;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    user-select: none;
}

.animatedEmoji {
    position: absolute;
    display: inline-block;
    transition: transform 0.5s ease;
    z-index: 10;
    animation: emojiAnimationFrame 10s ease-in-out;
    transform-origin: bottom center;
    opacity: 0;
}

@keyframes emojiAnimationFrame {
    0% {
        transform: translateY(0) rotate(-4deg);
        bottom: 0;
        opacity: 1;
    }
    25% {
        transform: translateY(-25px) rotate(4deg);
    }
    50% {
        transform: translateY(0) rotate(-4deg);
    }
    75% {
        transform: translateY(-25px) rotate(4deg);
        opacity: 1;
    }
    100% {
        transform: translateY(-25px) rotate(4deg);
        bottom: 120%;
        opacity: 0;
    }
}

.promptImageBorder{
    border: 1px solid red;
    position: fixed;
    /*height: 80%;
    width: 90%;*/
    top: 5%;
    left: 4%;
    right: 4%;
    bottom: 5%;

    z-index: 1000;
}

.liveContainer{    
    position: fixed;
    top: 6%;
    left: 6%;
    z-index: 10;
    display: flex;
    align-items: center;
    border: 1px solid red;
    width: fit-content;
    margin: 20px 0px 0 20px;
    padding: 1px 0 0 10px;
    background: white;
    border-radius: 10px;
}

.liveText{
    color: red;
    font-weight: 700;
    letter-spacing: 4px;
    font-size: 22px;
}

.liveDot {
	background: rgba(255, 82, 82, 1);
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
	margin: 11px;
	height: 20px;
	width: 20px;
	transform: scale(1);
	animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}
